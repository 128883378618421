<template>
  <div>
    <b-modal
      v-model="isModal"
      title="Agregar Free Spin"
      size="lg"
      centered
      @ok="handleAddFreeSpin"
      @hidden="resetForm"
      :ok-disabled="isSubmitting"
    >
      <p class="text-muted mb-2">
        <feather-icon icon="InfoIcon" size="16" class="mr-50" />
        Puedes agregar Free Spin de 3 maneras diferentes:
      </p>
      <b-list-group class="mb-2">
        <b-list-group-item
          v-for="option in freeSpinModes"
          :key="option.mode"
          :active="selectedMode === option.mode"
          class="d-flex align-items-center cursor-pointer"
          @click="selectMode(option.mode)"
        >
          <feather-icon
            :icon="option.icon"
            size="16"
            class="mr-1"
            :class="
              selectedMode === option.mode ? 'text-white' : 'text-primary'
            "
          />
          {{ option.text }}
        </b-list-group-item>
      </b-list-group>


      <b-form v-if="selectedMode">
        <b-row v-if="selectedMode !== 'global'">
          <b-col :md="selectedMode === 'game' ? '4' : '6'">
            <label
              >{{ $t("labels.supplier") }}
              <span class="text-danger">*</span></label
            >
            <b-overlay :show="loadingProducts" rounded="lg" opacity="0.6">
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <span class="sr-only">{{ $t("please-wait") }}</span>
                </div>
              </template>

              <v-select
                class="mb-1"
                v-model="form.product"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productsOptions"
                label="name"
                @input="productSelected"
                :reduce="product => ({ name: product.name, _id: product._id })"
                :placeholder="$t('labels.select-product')"
              >
              </v-select>
            </b-overlay>
          </b-col>

          <b-col
            :md="selectedMode === 'game' ? '4' : '6'"
            v-if="selectedMode !== 'global'"
          >
            <label>{{ $t("brands") }}</label>
            <b-overlay :show="loadingBrands" rounded="lg" opacity="0.6">
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <span class="sr-only">{{ $t("please-wait") }}</span>
                </div>
              </template>

              <v-select
                class="mb-1"
                v-model="form.brand"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brandsOptions"
                label="name"
                :reduce="brand => ({ name: brand.name, brandId: brand.brandId })"
                @input="brandSelected"
                :disabled="!form.product"
                :placeholder="$t('labels.select-brand')"
              >
              </v-select>
            </b-overlay>
          </b-col>

          <b-col md="4" v-if="selectedMode === 'game'">
            <label>{{ $t("games") }}</label>
            <b-overlay :show="loadingGames" rounded="lg" opacity="0.6">
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <span class="sr-only">{{ $t("please-wait") }}</span>
                </div>
              </template>

              <v-select
                class="mb-1"
                v-model="form.game"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="gamesOptions"
                label="gameName"
                :reduce="game => ({ name: game.gameName, gameId: game.gameId })"
                @input="gameSelected"
                :disabled="!form.brand"
                :placeholder="$t('labels.select-game')"
              >
              </v-select>
            </b-overlay>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" class="mt-1">
            <b-form-group label="Cantidad" label-for="qty">
              <b-form-input
                id="qty"
                v-model.number="form.qty"
                type="number"
                min="1"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6" class="mt-1">
            <b-form-group label="Monto Máximo (USD)" label-for="maxAmount">
              <b-form-input
                id="maxAmount"
                v-model.number="form.maxAmount"
                type="number"
                step="0.01"
                min="0"
                required
              />
            </b-form-group>
          </b-col>
          <b-col md="6" class="mt-1">
            <b-form-group
              label="Fecha de Expiración"
              label-for="expirationDate"
            >
              <flat-pickr
                v-model="form.expirationDate"
                class="form-control"
                :config="{
                  dateFormat: 'Y-m-d H:i',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" class="mt-1">
            <b-form-group label="Source" label-for="source">
              <b-form-input
                id="source"
                v-model="form.source"
                type="text"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <b-alert v-if="selectedMode" show variant="info" class="mt-2">
        <div class="alert-body">
          <feather-icon icon="InfoIcon" size="16" class="mr-2" />
          {{ modeDescription }}
        </div>
      </b-alert>

      <div v-else class="text-center text-muted my-3">
        <feather-icon icon="HandIcon" size="32" class="mb-1" />
        <p>Por favor seleccione una opción para continuar</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="secondary"
          @click="cancel()"
          :disabled="isSubmitting"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
          :disabled="!isFormValid || isSubmitting"
        >
          <b-spinner small v-if="isSubmitting" class="mr-1"></b-spinner>
          {{ isSubmitting ? "" : "Agregar" }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BOverlay,
  BSpinner,
  BAlert,
  BListGroup,
  BListGroupItem,
  BButton,
  BCardText,
  BCard,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import productStoreModule from "@/store/products";
import gamesStoreModule from "@/store/games";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    flatPickr,
    BCardText,
    BOverlay,
    BSpinner,
    BAlert,
    BListGroup,
    BListGroupItem,
    BButton,
    BCard,
    vSelect,
  },

  data() {
    return {
      isModal: false,
      playerId: null,
      form: {
        id: "",
        qty: 1,
        maxAmount: 0,
        expirationDate: moment().add(1, "days").toDate(),
        source: "Admin",
        brand: null,
        game: null,
        product: null,
      },
      selectedProduct: null,
      productsOptions: [],
      brandsOptions: [],
      gamesOptions: [],
      loadingProducts: false,
      loadingBrands: false,
      loadingGames: false,
      selectedMode: null,
      isSubmitting: false,
      freeSpinModes: [
        {
          mode: "global",
          icon: "ServerIcon",
          text: "Agregar Free Spin globales",
        },
        { mode: "brand", icon: "TagIcon", text: "Agregar Free Spin por marca" },
        {
          mode: "game",
          icon: "LayersIcon",
          text: "Agregar Free Spin por juego específico",
        },
      ],
    };
  },

  computed: {
    modeDescription() {
      switch (this.selectedMode) {
        case "global":
          return "Los Free Spins se aplicarán a todos los juegos disponibles";
        case "brand":
          return "Los Free Spins se aplicarán a todos los juegos de la marca seleccionada";
        case "game":
          return "Los Free Spins se aplicarán solo al juego específico seleccionado";
        default:
          return "";
      }
    },

    formPayload() {
      return {
        id: this.playerId,
        qty: this.form.qty,
        expirationDate: new Date(this.form.expirationDate).toISOString(),
        maxAmount: this.form.maxAmount,
        source: this.form.source,
        product: this.selectedMode === 'global' ? '' : this.form.product,
        brand: this.selectedMode === 'global' ? '' : this.form.brand,
        game: this.selectedMode === 'game' ? this.form.game: '',
      };
    },

    isFormValid() {
      const baseValidation =
        this.selectedMode &&
        this.form.qty > 0 &&
        this.form.maxAmount > 0 &&
        this.form.source &&
        this.form.expirationDate;

      switch (this.selectedMode) {
        case 'global':
          return baseValidation;
        case 'brand':
          return baseValidation && this.form.product && this.form.brand;
        case 'game':
          return baseValidation && this.form.product && this.form.brand && this.form.game;
        default:
          return false;
      }
    },
  },

  methods: {
    selectMode(mode) {
      this.selectedMode = mode;
      this.resetSelections();
    },

    resetSelections() {
      this.selectedProduct = null;
      this.selectedBrand = null;
      this.selectedGames = [];
      this.brandsOptions = [];
      this.gamesOptions = [];
    },

    async handleAddFreeSpin(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.isFormValid) return;

      try {
        this.isSubmitting = true;

        await this.$store.dispatch("usersModule/addFreeSpin", this.formPayload);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Free Spin Agregado",
            icon: "CheckIcon",
            variant: "success",
            text: "Free Spin agregado correctamente",
          },
        });

        this.resetForm();
        this.$emit("freespin-added");
      } catch (error) {
        console.error("Error adding free spin:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text:
              error.response?.data?.message || "Error al agregar free spins",
          },
        });
      } finally {
        this.isSubmitting = false;
      }
    },

    resetForm() {
      this.form = {
        id: this.playerId,
        qty: 1,
        maxAmount: 0,
        expirationDate: moment().add(1, "days").toDate(),
        source: "Admin",
        brand: null,
        game: null,
        product: null,
      };
      this.isModal = false;
      this.selectedMode = null;
      this.brandsOptions = [];
      this.gamesOptions = [];
    },

    async loadProducts() {
      try {
        this.loadingProducts = true;
        await this.$store.dispatch("productsModule/getProducts");
        this.productsOptions =
          this.$store.getters["productsModule/productsActives"];
      } catch (error) {
        console.error(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error cargando productos",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingProducts = false;
      }
    },

    async productSelected(product) {
      if (product) {
        this.form.product = product;
        this.form.brand = null;
        this.form.game = null;
        this.brandsOptions = [];
        this.gamesOptions = [];
        await this.loadBrands(product._id);
      } else {
        this.form.product = null;
        this.form.brand = null;
        this.form.game = null;
        this.brandsOptions = [];
        this.gamesOptions = [];
      }
    },

    async loadBrands(productId) {
      try {
        if (!productId) return;
        this.loadingBrands = true;
        const response = await this.$store.dispatch(
          "brandModule/index",
          productId
        );
        this.brandsOptions = response.data.brands;
      } catch (error) {
        console.error(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error cargando marcas",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingBrands = false;
      }
    },

    async brandSelected(brand) {
      if (brand) {
        this.form.brand = brand;
        this.form.game = null;
        await this.loadGames(this.form.product._id, brand.brandId);
      } else {
        this.form.brand = null;
        this.form.game = null;
        this.gamesOptions = [];
      }
    },

    async loadGames(productId, brandId) {
      try {
        if (!productId || !brandId) return;
        this.loadingGames = true;
        const brandIdN = this.brandsOptions.find(
          (brand) => brand._id === brandId
        )?.brandId;
        const response = await this.$store.dispatch("gamesModule/getGames", {
          producSelectId: productId,
          params: {
            page: 1,
            perPage: 1000,
            brandId: brandIdN,
          },
        });
        this.gamesOptions = response.data.games;
      } catch (error) {
        console.error(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error cargando juegos",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingGames = false;
      }
    },

    gameSelected(game) {
      this.form.game = game || null;
    },
  },

  mounted() {
    this.loadProducts();
  },

  created() {
    if (!store.hasModule("productsModule")) {
      store.registerModule("productsModule", productStoreModule);
    }
    if (!store.hasModule("gamesModule")) {
      store.registerModule("gamesModule", gamesStoreModule);
    }
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
