<template>
  <b-card class="card-custom-players">
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col v-if="typeUserLoged == 'Root'" md="5" inline>
        <b-form-group>
          <label class="mr-1 text-lg" for="customRadioInline1">Clientes </label>
          <v-select
            class="mb-1"
            v-model="filterSelect.selectCustomer"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="customerOptions"
            label="name"
            :reduce="(customer) => customer.customerId"
            placeholder="Cliente"
            value=""
            @input="searchDileayed"
          >
            <template #option="{ name }">
              <feather-icon
                icon="UserIcon"
                size="16"
                class="align-middle mr-50"
              />
              <span> {{ name }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group>
          <label class="mr-1">Monedas</label>
          <v-select
            class="mb-1"
            v-model="filterSelect.selectCurrency"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="currencyssOptions"
            placeholder="Moneda"
            value="Bs"
            @input="searchDileayed"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="5">
        <label class="mr-1">Buscar</label>
        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" />
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="usersList"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: '',
      }"
      styleClass="vgt-table"
      compactMode
    >
      <!-- format date createAt  -->
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'createdAt'">
          <p>{{ props.row.createdAt | formatDate }}</p>
        </div>
        <div v-else-if="props.column.field == 'segments'">
          <!-- mini table: _id, userId, user_id -->
          <div v-if="props.row.segments">
            <div
              v-for="(item, index) in props.row.segments"
              :key="index"
              class="text-truncate"
            >
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="props.column.field == 'totalUSDBet'">
          <div
            v-if="
              props.row.totalUSDBet !== undefined &&
              props.row.totalUSDBet !== null
            "
          >
            {{
              formatAmount({
                amount: props.row.totalUSDBet,
                currency: props.row.currency,
              })
            }}
          </div>
        </div>
        <div v-else-if="props.column.field == 'ids'">
          <!-- mini table: _id, userId, user_id -->
          <div class="d-flex text-nowrap align-items-center">
            <div class="d-flex flex-column mr-1">
              <p class="mb-0 font-weight-bold">_id:</p>
              <p class="mb-0 font-weight-bold">userId:</p>
              <p class="mb-0 font-weight-bold">user_id:</p>
            </div>
            <div class="d-flex flex-column mr-1">
              <p class="mb-0">{{ props.row._id }}</p>
              <p class="mb-0">{{ props.row.userId }}</p>
              <p class="mb-0">{{ props.row.user_id }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="props.column.field == 'isDemo'">
          <b-form-group>
            <b-form-checkbox
              v-model="props.row.isDemo"
              :id="'isDemo' + props.row._id"
              class="custom-control-success"
              switch
              inline
              @change="setIsDemo(props.row)"
            />
          </b-form-group>
        </div>

        <div v-else-if="props.column.field == 'freeSpin'">
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              icon="EyeIcon"
              size="24"
              class="mr-1 cursor-pointer text-primary"
              @click="showFreeSpin(props.row)"
            />
            <feather-icon
              icon="PlusIcon"
              size="24"
              class="cursor-pointer text-success"
              @click="addFreeSpin(props.row)"
            />
          </div>
        </div>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="serverParams.limit"
                :options="pages"
                class="mx-1"
                @input="onPerPageChange"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="serverParams.limit"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="onPageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> -->

    <section style="padding: 30px 0 0px 0px">
      <b-row>
        <b-col cols="3">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="serverParams.limit"
              :options="dynamicPages"
              class="mx-1"
              @input="onPerPageChange"
            />
            <span class="text-nowrap"> of {{ usersList.length }} entries </span>
          </div>
        </b-col>
        <b-col cols="9">
          <b-pagination
            v-model="current_page"
            :total-rows="total"
            :per-page="per_page"
            first-number
            @change="getUsers"
            align="right"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Modal para FreeSpin Details -->
    <b-modal
      v-model="showFreeSpinModal"
      title="Detalles de Free Spins"
      :size="groupedFreeSpins.length ? 'xl' : 'md'"
      centered
      hide-footer
      scrollable
      modal-class="modal-primary"
    >
      <div class="modal-body p-1" style="max-height: 70vh; overflow-y: auto">
        <b-row>
          <b-col v-if="groupedFreeSpins.length">
            <b-row class="justify-content-center">
              <b-col cols="3" class="bg-light-success rounded-lg mb-2 p-1">
                <h4 class="text-center text-success">
                  <feather-icon icon="GiftIcon" size="22" class="mr-50 mb-50" />
                  {{ selectedPlayerFreeSpin.length }} Free Spins disponibles
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="(group, index) in groupedFreeSpins"
                :key="index"
                cols="12"
                sm="12"
                md="12"
                lg="4"
                xl="2"
                class="mb-1"
              >
                <b-card no-body class="border position-relative h-100">
                  <b-badge
                    v-if="group.count > 1"
                    variant="danger"
                    pill
                    class="position-absolute p-1"
                    style="top: -10px; right: -10px"
                  >
                    x{{ group.count }}
                  </b-badge>

                  <b-card-body>
                    <div
                      class="d-flex justify-content-between align-items-center mb-1"
                    >
                      <h4 class="font-weight-bold mb-0">
                        <feather-icon
                          icon="DollarSignIcon"
                          size="22"
                          class="text-success mr-50"
                        />
                        {{
                          formatAmount({
                            amount: group.maxAmount,
                            currency: "USD",
                          })
                        }}
                      </h4>
                    </div>

                    <h6 class="d-flex align-items-center mb-1">
                      <feather-icon
                        icon="CalendarIcon"
                        size="18"
                        class="mr-1 text-warning"
                      />
                      <span class="font-weight-semibold">
                        Expira: {{ group.expirationDate | formatDateNoTime }}
                      </span>
                    </h6>

                    <h6 class="d-flex align-items-center mb-1">
                      <feather-icon
                        icon="CodesandboxIcon"
                        size="18"
                        class="text-primary mr-1"
                      />
                      <span>
                        Producto:
                        <b-badge
                          :variant="
                            group.product.name ? 'light-primary' : 'light-secondary'
                          "
                          class="font-weight-bold"
                        >
                          {{ group.product.name || "No asignado" }}
                        </b-badge>
                      </span>
                    </h6>

                    <h6 class="d-flex align-items-center mb-1">
                      <feather-icon
                        icon="TagIcon"
                        size="18"
                        class="text-primary mr-1"
                      />
                      <span>
                        Marca:
                        <b-badge
                          :variant="
                            group.brand.name ? 'light-primary' : 'light-secondary'
                          "
                          class="font-weight-bold"
                        >
                          {{ group.brand.name || "No asignado" }}
                        </b-badge>
                      </span>
                    </h6>

                    <h6 class="d-flex align-items-center">
                      <feather-icon
                        icon="CpuIcon"
                        size="18"
                        class="text-primary mr-1"
                      />
                      <span>
                        Juego:
                        <b-badge
                          :variant="
                            group.game.name ? 'light-primary' : 'light-secondary'
                          "
                          class="font-weight-bold"
                        >
                          {{ group.game.name || "No asignado" }}
                        </b-badge>
                      </span>
                    </h6>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            v-else
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <div class="bg-light-danger rounded-lg mb-2 p-1">
              <h5 class="text-center text-danger mb-0">
                <feather-icon icon="XCircleIcon" size="20" class="mr-50" />
                No hay free spins disponibles
              </h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <addModalFreeSpin
      ref="addFreeSpinModal"
      @freespin-added="handleFreeSpinAdded"
    />
  </b-card>
</template>

<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BCardGroup,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BAlert,
  BCardBody,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import moment from "moment";
import { props } from "vue-prism-component";
import addModalFreeSpin from "./addModalFreeSpin.vue";

export default {
  components: {
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BAlert,
    BCardBody,
    flatPickr,
    addModalFreeSpin,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate,
    formatDateNoTime(value) {
      if (!value) return "";
      return moment(value).format("YYYY-MM-DD");
    },
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    customerOptions: [],
    showPassword: false,
    required,
    openModal: false,
    loadingData: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    formData: {
      name: "",
      lastname: "",
      customerId: "",
      username: "",
      password: "",
      typeUser: "",
    },
    filterSelect: {
      dateTo: null,
      dateFrom: null,
      selectCustomer: "",
      selectProduct: "",
      selectCurrency: "",
      q: "",
    },
    columns: [
      { label: "Nombre", field: "name" },
      { label: "usuario", field: "username" },
      { label: "Segmento ", field: "segments" },
      { label: "Email", field: "email" },
      { label: "Moneda", field: "currency" },
      { label: "Total Apostado", field: "totalUSDBet" },
      { label: "CustomerId", field: "customerId" },
      { label: "isd", field: "ids" },
      { label: "Demo", field: "isDemo" },
      { label: "Free Spin", field: "freeSpin" },
      { label: "createdAt", field: "createdAt" },
    ],
    serverParams: {
      page: 1,
      limit: 20,
    },
    pages: [20, 40, 60, 100, 120, 200, 500, 1000],
    current_page: 1,
    per_page: 10,
    total: 0,
    maxLimit: 1000,
    timeOutSearchCustomer: null,
    usersList: [],
    currencyssOptions: [
      "ARS",
      "BRL",
      "CLP",
      "COP",
      "EUR",
      "MXN",
      "MYR",
      "PEN",
      "PYG",
      "USD",
      "UYU",
      "VES",
      "XAF",
    ],
    timeoutSearch: null,
    showFreeSpinModal: false,
    selectedPlayerFreeSpin: [],
    selectedPlayerId: "",
  }),
  watch: {
    searchQuery() {
      this.searchDileayed();
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    typeUserOptions() {
      if (this.typeUserLoged == "Root") {
        return [
          { text: "Super Admin", value: "superadmin" },
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      } else {
        return [
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      }
    },
    dynamicPages() {
      let filteredPages = this.pages.filter((page) => page <= this.maxLimit);
      if (this.total < this.maxLimit && !filteredPages.includes(this.total)) {
        filteredPages.push(this.total);
      }
      return filteredPages.map((page) => ({
        value: page,
        text: page.toString(),
      }));
    },
    groupedFreeSpins() {
      const groups = {};
      this.selectedPlayerFreeSpin.forEach((spin) => {
        const key = `${spin.maxAmount}-${spin.expirationDate}-${spin.brand.brandId}-${spin.game.gameId}-${spin.product._id}`;
        if (!groups[key]) {
          groups[key] = {
            maxAmount: spin.maxAmount,
            expirationDate: spin.expirationDate,
            product: spin.product,
            brand: spin.brand,
            game: spin.game,
            count: 0,
          };
        }
        groups[key].count++;
      });
      return Object.values(groups).sort(
        (a, b) => new Date(a.expirationDate) - new Date(b.expirationDate)
      );
    },
  },
  methods: {
    searchDileayed() {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.getUsers();
      }, 500);
    },
    async onPerPageChange(limit) {
      this.getUsers(1, limit);
    },
    formatAmount({ amount, currency }) {
      if (amount !== undefined && amount !== null) {
        const amountFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        }).format(amount);
        return amountFormat;
      }
      return ""; // Retorna una cadena vacía si el monto no es válido
    },
    async dataCustomer() {
      try {
        const res = await this.$store.dispatch("customerModule/getCustomers", {
          paginate: {
            page: 1,
            perPage: 1000,
            sortDesc: true,
          },
        });
        this.customerOptions = res.data.customers;
      } catch (error) {
        console.log(error);
      }
    },

    async getUsers(page = 1, numberItem = 10) {
      try {
        let params = {
          paginate: {
            page: page,
            perPage: numberItem,
            sortDesc: true,
          },
        };
        if (this.searchQuery) {
          params.search = this.searchQuery;
        }
        if (this.filterSelect.selectCurrency) {
          params.currency = this.filterSelect.selectCurrency;
        }
        if (this.filterSelect.selectCustomer) {
          params.customerId = this.filterSelect.selectCustomer;
        }
        this.loadingData = true;
        const res = await this.$store.dispatch(
          "usersModule/getUsersPlayers",
          params
        );
        this.usersList = res.users;
        this.total = res.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    async setIsDemo(userClient) {
      let payload = {
        _id: userClient._id,
        customerId: userClient.customerId,
        isDemo: userClient.isDemo,
      };
      console.log(payload);

      await this.$store.dispatch("customerModule/setIsDemo", payload);
      this.getUsers(this.current_page);
    },
    showGames(user) {
      this.$refs.games.user = JSON.parse(JSON.stringify(user));
      this.$refs.games.modalShow = true;
    },
    showProduct(user) {
      this.$refs.products.user = JSON.parse(JSON.stringify(user));
      this.$refs.products.modalShow = true;
    },
    userChangePassword(user) {
      this.$refs.userChangePassword.user = JSON.parse(JSON.stringify(user));
      this.$refs.userChangePassword.modalShow = true;
    },
    async showFreeSpin(player) {
      try {
        const response = await this.$store.dispatch("usersModule/getFreeSpin", {
          id: player._id,
        });
        this.selectedPlayerFreeSpin = response.data;
        this.showFreeSpinModal = true;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Error al obtener free spins",
          },
        });
      }
    },
    addFreeSpin(player) {
      this.$refs.addFreeSpinModal.playerId = player._id;
      this.$refs.addFreeSpinModal.isModal = true;
      this.selectedPlayerId = player._id;
    },

    async handleFreeSpinAdded() {
      await this.showFreeSpin({ _id: this.selectedPlayerId });
    },
  },
  mounted() {
    this.getUsers();
    this.dataCustomer();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.per-page-selector {
  width: 90px;
}
.form-group {
  margin-bottom: 0 !important;
}
.card-custom-players .card-body {
  overflow: auto;
}
</style>
